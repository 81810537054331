import type { StatusResponseData } from '@/types/status';

export function statusModel(responseData: any | null) {
  const response: StatusResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    getResult(): string {
      return response?.data?.message ?? response?.data ?? '';
    },
  };
}
