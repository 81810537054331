import type { JobModel, JobResponse } from '@/types/jobModel';

export function jobModel(data: JobResponse | null) {
  let whoWeAre: string;
  let description1: string;
  let description2: string;
  let fullAddress: string;

  return {
    getId(): string {
      return data?.id ?? '';
    },

    getTitle(): string {
      return data?.title ?? '';
    },

    getCareerLevelId(): string {
      return String(data?.careerLevelId);
    },

    getCareerLevel(): string {
      return data?.careerLevel ?? '';
    },

    getDepartmentId(): string {
      return String(data?.departmentId);
    },

    getDepartment(): string {
      return data?.department ?? '';
    },

    getScopeId(): string {
      return String(data?.scopeId);
    },

    getSeoScope(): string | string[] {
      const scopeMapping: { [key: string]: string | string[] } = {
        7365: ['FULL_TIME', 'PART_TIME'],
        4465: 'FULL_TIME',
        4466: 'PART_TIME',
        4532: 'TEMPORARY',
        8891: 'INTERN',
      };
      return scopeMapping[data?.scopeId ?? 0] || 'OTHER';
    },

    getScope(): string {
      return data?.scope ?? '';
    },

    getContinentId(): string {
      return String(data?.continentId);
    },

    getContinent(): string {
      return data?.continent ?? '';
    },

    getCountryId(): string {
      return String(data?.countryId);
    },

    getCountry(): string {
      return data?.country ?? '';
    },

    getRegionId(): string {
      return String(data?.regionId);
    },

    getRegion(): string {
      return data?.region ?? '';
    },

    getLocationId(): string {
      return String(data?.locationId);
    },

    getLocation(): string {
      return data?.location ?? '';
    },

    getZipCode(): string {
      return data?.zipCode ?? '';
    },

    getFullAddress(): string {
      if (!fullAddress) {
        const streetAddress = data?.streetAddress ?? '';
        const zipCodeAndCity = `${this.getZipCode()} ${this.getLocation()}`.trim();
        fullAddress = [streetAddress, zipCodeAndCity].filter(Boolean).join(', ');
      }
      return fullAddress;
    },

    getDivisionId(): string {
      return String(data?.divisionId);
    },

    getDivision(): string {
      return data?.division ?? '';
    },

    getWhoWeAre(): string {
      whoWeAre = whoWeAre || removeEmptyHtmlParagraphs(data?.whoWeAre ?? '');
      return whoWeAre;
    },

    getDescription1(): string {
      description1 = description1 || removeEmptyHtmlParagraphs(data?.desc1 ?? '');
      return description1;
    },

    getDescription2(): string {
      description2 = description2 || removeEmptyHtmlParagraphs(data?.desc2 ?? '');
      return description2;
    },

    getHrName(): string {
      return data?.hrName ?? '';
    },

    getHrMail(): string {
      return data?.hrMail ?? '';
    },

    getPublishedDate(): number {
      return data?.published ?? 0;
    },

    getUrl(): string {
      return `${this.getTitle()
        .replace(/\W/g, ' ')
        .replace(/\s{1,5}/g, '-')
        .toLocaleLowerCase()}-${this.getId()}`;
    },

    getFutureLocaleStartDate(): string {
      const startDate: Date = this.getStartDate();
      const isInTheFuture: boolean = startDate > new Date();
      return isInTheFuture ? this.getLocaleStartDate() : '';
    },

    getStartDate(): Date {
      return new Date(data?.startDate ?? '');
    },

    getLocaleStartDate(): string {
      return this.getStartDate().toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric' });
    },

    isFranchiseDepartment(): boolean {
      return data?.department === constants.JOBS.DEPARTMENT.FRANCHISE;
    },

    isConcessionDepartment(): boolean {
      return data?.department === constants.JOBS.DEPARTMENT.CONCESSION;
    },

    containsSearchTerm(searchTerm: string): boolean {
      const title: string = data?.title?.toLocaleLowerCase() ?? '';
      const department: string = data?.department?.toLocaleLowerCase() ?? '';
      const careerLevel: string = data?.careerLevel?.toLocaleLowerCase() ?? '';
      const city: string = data?.location?.toLocaleLowerCase() ?? '';
      const country: string = data?.country?.toLocaleLowerCase() ?? '';
      const division: string = data?.division?.toLocaleLowerCase() ?? '';
      const deployment: string = data?.scope?.toLocaleLowerCase() ?? '';
      if (!title && !careerLevel && !department && !city && !country && !division && !deployment) {
        return false;
      }
      const searchTerms: string[] = searchTerm.toLocaleLowerCase().split(' ');
      return searchTerms.every(
        (term) =>
          title?.includes(term) ||
          department?.includes(term) ||
          careerLevel?.includes(term) ||
          city?.includes(term) ||
          country?.includes(term) ||
          division?.includes(term) ||
          deployment?.includes(term),
      );
    },

    containsFilterValue(filterName: string, filterValue: string | string[]): boolean {
      let value = '';
      if (filterName === 'department') {
        value = this.getDepartmentId();
      } else if (filterName === 'continent') {
        value = this.getContinentId();
      } else if (filterName === 'country') {
        value = this.getCountryId();
      } else if (filterName === 'region') {
        value = this.getRegionId();
      } else if (filterName === 'division') {
        value = this.getDivisionId();
      } else if (filterName === 'career') {
        value = this.getCareerLevelId();
      } else if (filterName === 'scope') {
        value = this.getScopeId();
      }
      if (!value) {
        return false;
      }
      if (Array.isArray(filterValue)) {
        return filterValue.includes(value);
      }
      return value === filterValue;
    },

    getGtmTrackData() {
      return {
        item_id: this.getId(),
        item_name: this.getTitle(),
        start_date: this.getFutureLocaleStartDate(),
        scope: this.getScope(),
        location: this.getRegion(),
        country: this.getCountry(),
        department: this.getDepartment(),
      };
    },

    getData() {
      return data;
    },
  };
}

export function jobListModel(responseData: { data: JobResponse[] } | null) {
  return {
    getList(): JobModel[] {
      return responseData?.data?.map((item: any) => jobModel(item)) ?? [];
    },
  };
}
